import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src="/images/me.jpeg" alt="" />
      </Link>
      <header>
        <h2>Nati Abebe</h2>
        <p><a href="mailto:natiabebe12@gmail.com">me@natiabebe.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Nati. I like building things.
        More things are coming soon here...
      </p>
      <ul className="actions">
        <li>
          <a href="https://www.linkedin.com/in/nabebe/" className="button">Learn More in LinkedIn</a> 
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Nati Abebe <Link to="/">natiabebe.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
