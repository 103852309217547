const routes = [
  {
    index: true,
    label: "Nati Abebe",
    path: '/',
  },
  {
    label: 'About',
   // path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
  //  path: '/projects',
  },
 // {
 //   label: 'Stats',
  //  path: '/stats',
 // },
  {
    label: 'Speaking Events',
  //  path: '/contact',
  },
];

export default routes;
